import {Component, HostListener, OnInit} from "@angular/core";
import {BsModalService} from "ngx-bootstrap/modal";
import {ContextMenuHelperService} from "./context-menu-helper.service";
import {AppUI, CardOcrSDK, DisplayOrientation} from "@identy/identy-ocr";
import {AppUiOption, DisplayModes} from "../../../modules/models/types";

@Component({
  selector: "app-title-context-menu",
  templateUrl: "./title-context-menu.component.html",
  styleUrls: ["./title-context-menu.component.css"]
})
export class TitleContextMenuComponent implements OnInit {
  dropdown: any;
  isOpen: boolean;

  run_face: boolean;
  run_finger: boolean;
  displayMode: DisplayOrientation;
  ocr_version: string
  finger_version: string = "4.2.7"
  face_version: string = "4.0.2"
  @HostListener( "document:click", ["$event"])  docClick(event) {
    if (event.target.classList.contains("dropdown-toggle")) {
      return;
    }

    const closest = this.getClosest(event.target, "ul");
    if (this.isOpen && !closest) {
      document.getElementById("btn-menu").click();
    }
  }

  constructor(private modalService: BsModalService, private contextMenuHelper: ContextMenuHelperService) {

    this.run_face = true;
    this.run_finger = false;
    this.ocr_version = "2.8.0"
    this.update();
  }

  ngOnInit() {}

  update() {
    setTimeout(() => {

      this.contextMenuHelper.selection.run_face = this.run_face;
      this.contextMenuHelper.selection.run_finger = this.run_finger;

    }, 10);

  }

  doOpen(acc: String) {

    for (const key of Object.keys(this.dropdown)) {
      if (key !== acc ) {
        this.dropdown[key] = false;
      }
    }

  }

  getClosest(elem, selector) {
    const ep: any = Element.prototype;
    if (!ep.matches) {
      ep.matches =
        ep.matchesSelector ||
        ep.mozMatchesSelector ||
        ep.msMatchesSelector ||
        ep.oMatchesSelector ||
        ep.webkitMatchesSelector ||
        function(s) {
          var matches = (this.document || this.ownerDocument).querySelectorAll(s),
            i = matches.length;
          while (--i >= 0 && matches.item(i) !== this) {}
          return i > -1;
        };
    }

    // Get the closest matching element
    for ( ; elem && elem !== document; elem = elem.parentNode ) {
      if ( elem.matches( selector ) ) return elem;
    }
    return null;

  };

  onShown() {
    this.isOpen = true;
  }
  onHide() {
    this.isOpen = false;
  }

}
