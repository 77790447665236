<div class="app_finger_selection_container">
  <div class="finger_selection">
    <div class="container">
      <div class="row" style="text-align: center">

        <div class="col" *ngFor="let card of card_faces">
          <p-checkbox shape="curve" color="success" [checked]="card.selected" (change)="card.selected = $event.checked" [disabled] = "card.disabled" (click)="updateSelection()">
            <svg p-svg class="svg-icon-green" viewBox="0 0 20 20">
              <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style="stroke: white;fill:white;"></path>
            </svg>
            {{card.label}}
          </p-checkbox>
        </div>

      </div>
      <div style="text-align: center; padding-top: 10px">
        <app-id-type></app-id-type>
      </div>
    </div>
  </div>
</div>
