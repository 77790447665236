<style></style>

<div class="content" role="main">

  <div class="row" style="margin-bottom: 35px; margin-top: 25px">
    <h3 style="text-align: center; font-size: 22pt; font-weight: bold; font-family: 'Hind'; color: #157842">Completed
    </h3>
  </div>
  <div class="row">

    <div class="col-12">
      <p style="font-size: 15px; font-family: 'Hind'; color:#157842;">SURNAME</p>
      <p style="font-size: 18px; color:#000;">ADEKUNLE</p>
    </div>

    <div class="col-12" style="margin-top: 25px;">
      <p style="font-size: 15px; font-family: 'Hind'; color:#157842;">FIRST NAME</p>
      <p style="font-size: 18px; color:#000;">JAMES</p>
    </div>

    <div class="col-12" style="margin-top: 25px;">
      <p style="font-size: 15px; font-family: 'Hind'; color:#157842;">MIDDLE NAME</p>
      <p style="font-size: 18px; color:#000;">MUSA</p>
    </div>

    <div class="col-12" style="margin-top: 25px;">
      <p style="font-size: 15px; font-family: 'Hind'; color:#157842;">TRACKING ID</p>
      <p style="font-size: 18px; color:#000;">617KCTBPE9IJQZA</p>
    </div>

    <div class="col-12" style="margin-top: 45px;">
      <button class="btn btn-md btn-block" style="font-family: 'Inter'; font-size: 13px; padding: 13px 0px; background: #10994A; color: #fff;">SHARE</button>
    </div>

    <div class="col-12" style="margin-top: 15px;">
      <button class="btn btn-md btn-block" style="font-family: 'Inter'; font-size: 13px; padding: 13px 0px; background: #10994A; color: #fff;">VIEW OR PRINT SLIP</button>
    </div>

    <div class="col-12" style="margin-top: 15px;">
      <button class="btn btn-md btn-block" style="font-family: 'Inter'; font-size: 13px; padding: 13px 0px; background: #10994A; color: #fff;">GO TO MAIN MENU</button>
    </div>
  </div>
</div>
