import {Component, OnInit} from "@angular/core";
import {CardDetectionMode} from "@identy/identy-ocr";
import {ContextMenuHelperService} from "../title-bar/title-context-menu/context-menu-helper.service";
import {CardSelectionHelperService} from "./card-selection-helper.service";

@Component({
  selector: "app-card-face-selection",
  templateUrl: "./card-face-selection.component.html",
  styleUrls: ["./card-face-selection.component.css"]
})
export class CardFaceSelectionComponent implements OnInit {

  public card_faces = [
    {
      label: 'Card Front',
      selected: true,
      disabled: false,
      face: CardDetectionMode.FRONT
    },
    {
      label: 'Card Back',
      selected: true,
      disabled: false,
      face: CardDetectionMode.BACK

    }
  ]

  constructor(private contextMenuHelper: ContextMenuHelperService,
              private cardServiceHelper: CardSelectionHelperService) {}

  ngOnInit() {
    this.updateSelection();

    this.contextMenuHelper.cardSelection.subscribe((card) => {
      this.card_faces.filter((c) => {return c.face === CardDetectionMode.BACK}).forEach((c) => {c.selected  = !["PASSPORT", "MEXICO_PASSPORT", "A4", "TANZANIA_VOTER_ID", "TANZANIA_DL", "TANZANIA_NIDA"].some(function(v) { return card.name.indexOf(v) >= 0; }); c.disabled = !c.selected})
      this.updateSelection();
    })
  }

  updateSelection() {

    setTimeout(() => {
      const selectedCards = this.card_faces.filter((card) => {
        return card.selected;
      }).map((card) => { console.log(card.face);return card.face});

      this.cardServiceHelper.cardSelection.next(selectedCards);
    }, 10);

  }

}
