import {Component, Input, OnInit} from '@angular/core';
import {Utils} from "../../../../modules/helpers/Utils";

@Component({
  selector: 'app-extracted-fingers',
  templateUrl: './extracted-fingers.component.html',
  styleUrls: ['./extracted-fingers.component.css']
})
export class ExtractedFingersComponent implements OnInit {

  @Input() response: any;
  fingers: Array<string> = [];
  constructor() { }

  ngOnInit(): void {
    const hand_orders = ["LEFT", "RIGHT"];
    const finger_orders = ["INDEX", "MIDDLE", "RING", "LITTLE", "THUMB"];
    for (const hand_order of hand_orders) {
      for (const finger_order of finger_orders) {
        const val = `(${hand_order},${finger_order})`;
        if (this.response.hasOwnProperty(val)) {
          const op = "data:image/jpg;base64," + this.response[val]['templates']['PNG']['DEFAULT'];
          this.fingers.push(op);
        }
      }
    }
  }

  showLarge(image_string) {
    Utils.showLarge(image_string);
  }

}
