import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { SdkService } from "src/app/sdk.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-nimc-web-capture",
  templateUrl: "./nimc-web-capture.component.html",
  styleUrls: ["./nimc-web-capture.component.scss"],
})
export class NimcWebCaptureComponent implements OnInit {
  isLoading = true;
  face_capture: string;
  clearedFace: string;

  base64Image: any;
  base64String: any;

  constructor(
    private sdkService: SdkService,
    private _sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.captureFace();
  }

  transform() {
    return this._sanitizer.bypassSecurityTrustResourceUrl(this.base64Image);
  }

  refreshCapture() {
    window.location.reload();
  }

  captureFace() {
    this.sdkService
      .capture_face()
      .then((res) => {
        console.log(res.data);
        // this.face_capture = res.data.templates.PNG;
        // console.log("face capture response ==>>", this.face_capture)

        // window.parent.postMessage(
        //   {
        //     image: this.face_capture,
        //     status: 200,
        //     message: "Capture successful!",
        //   },
        //   "*"
        // );
      })
      .catch((err) => {
        Swal.fire({
          title: "Liveliness Failed!",
          // text: "Eyes Closed!",
          icon: "error",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Try Again",
        }).then((isConfirm) => {
          if (isConfirm.isConfirmed) {
            this.captureFace();
          }
        });
      });
  }
}
