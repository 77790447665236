import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppComponent } from "./app.component";
import { TitleBarComponent } from "./components/title-bar/title-bar.component";
import { TitleContextMenuComponent } from "./components/title-bar/title-context-menu/title-context-menu.component";
import {
  BsDropdownModule,
  CollapseModule,
  TabsModule,
  TabsetConfig,
} from "ngx-bootstrap";
import { FormsModule } from "@angular/forms";
import { SplashComponent } from "./components/title-bar/splash/splash.component";
import { ModalModule } from "ngx-bootstrap";
import { CommonModule } from "@angular/common";
import { SdkRunComponent } from "./components/sdk-run/sdk-run.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { IndexComponent } from "./components/index/index.component";
import { ProgressDialogComponent } from "./components/dialogs/progress-dialog/progress-dialog.component";
import { TransactionDialogComponent } from "./components/dialogs/transaction-dialog/transaction-dialog.component";
import { CardFaceSelectionComponent } from "./components/card-selection/card-face-selection.component";
import { TypeOfPipe } from "./modules/helpers/type-of.pipe";
import { UserInputComponent } from "./components/dialogs/user-input/user-input.component";
import { NgxPrettyCheckboxModule } from "ngx-pretty-checkbox";
import { IdTypeComponent } from "./components/id-type/id-type.component";
import { SpoofDialogComponent } from "./components/dialogs/spoof-dialog/spoof-dialog.component";
import { NoFaceDialogComponent } from "./components/dialogs/no-face-dialog/no-face-dialog.component";
import { FaceMatchComponent } from "./components/dialogs/transaction-dialog/face-match/face-match.component";
import { OcrResultComponent } from "./components/dialogs/transaction-dialog/ocr-result/ocr-result.component";
import { ErrorDialogComponent } from "./components/dialogs/error-dialog/error-dialog.component";
// import { FaceRetryComponent } from './components/dialogs/transaction-dialog/face-retry/face-retry.component';
import { ExtractedFaceComponent } from "./components/dialogs/transaction-dialog/extracted-face/extracted-face.component";
import { ExtractedFingersComponent } from "./components/dialogs/transaction-dialog/extracted-fingers/extracted-fingers.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { LayoutsModule } from "./pages/layouts/layouts.module";
import { PagesModule } from "./pages/pages.module";
import { ToastrModule } from "ngx-toastr";
import { JwtInterceptor } from "./core/services/jwt.interceptor";
const routes = [
  {
    path: "",
    // redirectTo: "app",
    redirectTo: "verxide",
    pathMatch: "full",
  },
  // {
  //   path: "app", component: IndexComponent
  // }
  {
    path: "verxid",
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
  },
];

@NgModule({
  declarations: [
    AppComponent,
    TitleBarComponent,
    TitleContextMenuComponent,
    SplashComponent,
    SdkRunComponent,
    IndexComponent,
    ProgressDialogComponent,
    TransactionDialogComponent,
    CardFaceSelectionComponent,
    TypeOfPipe,
    UserInputComponent,
    IdTypeComponent,
    NoFaceDialogComponent,
    FaceMatchComponent,
    OcrResultComponent,
    ErrorDialogComponent,
    // FaceRetryComponent,
    ExtractedFaceComponent,
    ExtractedFingersComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    BsDropdownModule.forRoot(),
    CollapseModule.forRoot(),
    ModalModule.forRoot(),
    RouterModule.forRoot(routes, { enableTracing: false }),
    FormsModule,
    NgxPrettyCheckboxModule,
    ToastrModule.forRoot({
      maxOpened: 3,
      autoDismiss: true,
    }),
    TabsModule.forRoot(),
    FormsModule,
    PagesModule,
    LayoutsModule,
    NgSelectModule,
  ],
  providers: [
    TabsetConfig,
    // { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  entryComponents: [
    ProgressDialogComponent,
    TransactionDialogComponent,
    UserInputComponent,
    SpoofDialogComponent,
    NoFaceDialogComponent,
    ErrorDialogComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
