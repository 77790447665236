<div class="btn-group" dropdown placement="bottom center">
  <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic">
    {{this.selected.name}} <span class="caret"></span>
  </button>
  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic" style="height: 200px; overflow: auto">
    <ng-container *ngFor="let idtype of id_types">
      <li role="menuitem"  (click)="select(idtype)" *ngIf="idtype.visible">{{idtype.name}}</li>
      <li class="divider dropdown-divider" *ngIf="idtype.visible"></li>
    </ng-container>
  </ul>
</div>
