import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { SdkService } from "src/app/sdk.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-face-capture",
  templateUrl: "./face-capture-rear.component.html",
  styleUrls: ["./face-capture-rear.component.scss"],
})
export class FaceCaptureRearComponent implements OnInit {
  isLoading = true;
  face_capture: string;
  clearedFace: string;

  base64Image: any;
  base64String: any;

  constructor(
    private sdkService: SdkService,
    private _sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.captureFace();
  }

  transform() {
    return this._sanitizer.bypassSecurityTrustResourceUrl(this.base64Image);
  }

  refreshCapture() {
    window.location.reload();
  }

  captureFace() {
    this.sdkService
      .capture_face(true)
      .then((res) => {
        if (res.icao_data.background_continuity < 50) {
          Swal.fire({
            title: "Detection Failed!",
            text: 'Background not clear.',
            // text: "Keep a straight face and remove any object from face.",
            icon: "error",
            confirmButtonColor: "#3085D6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Try Again",
          }).then((isConfirm) => {
            if (isConfirm.isConfirmed) {
              this.captureFace();
            }
          });
        } else {
          console.log("this is the face capture response ==>>", res.data);
          // const face_capture = res.data.templates.PNG;
          this.face_capture = res.data.templates.PNG;
          this.base64Image = `data:image/png;base64, ${res.data.templates.PNG}`;
          console.log("face capture response ==>>", this.face_capture)

          window.parent.postMessage(
            {
              image: this.face_capture,
              status: 200,
              message: "Capture successful!",
            },
            "*"
          );
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Liveliness Failed!",
          // text: "Eyes Closed!",
          icon: "error",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Try Again",
        }).then((isConfirm) => {
          if (isConfirm.isConfirmed) {
            this.captureFace();
          }
        });
      });
  }
}
