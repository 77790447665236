<div class="use-case-label"></div>
<div class="run-section">
  <div class="flex-container">
    <button  class="btn btn-primary" style="text-align: center; width: 100%; margin: 0 5% 0 5%; font-size: 25px" (click)="trigger_capture()" [disabled]="!allow">Onboarding</button>
    <button  class="btn btn-primary" style="text-align: center; width: 100%; margin: 0 5% 0 5%; font-size: 25px" (click)="capture_face()">Capture Face</button>
    <button  class="btn btn-primary" style="text-align: center; width: 100%; margin: 0 5% 0 5%; font-size: 25px" (click)="capture_finger()">Capture Finger</button>
  </div>

  <div style="align-content: center; padding-top: 20px;padding-left: 25%">
    <ol type="1">
      <li>Capture front of ID Card</li>
      <li>Capture back of ID Card</li>
      <li>Take a Selfie</li>
    </ol>
  </div>
</div>
