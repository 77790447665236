<div class="modal-body">
  <!-- Capture failed, move slightly to change lighting. -->
  {{ data }}
</div>
<div class="modal-footer">
  <button
    class="btn btn-md btn-primary btn-close btn-santander"
    (click)="onCloseClicked()"
  >
    Close
  </button>
  <button
    class="btn btn-md btn-primary btn-close btn-santander"
    (click)="onRetryClicked()"
  >
    Retry
  </button>
</div>
