import {Component, Input, OnInit} from "@angular/core";
import {BsModalRef} from "ngx-bootstrap";
import {DomSanitizer, SafeHtml, SafeUrl} from "@angular/platform-browser";
import {Localization} from "@identy/identy-ocr";
import {TransactionInput} from "../../../modules/types";
import {ContextMenuHelperService} from "../../title-bar/title-context-menu/context-menu-helper.service";

@Component({
  selector: "app-transaction-dialog",
  templateUrl: "./transaction-dialog.component.html",
  styleUrls: ["./transaction-dialog.component.css"]
})
export class TransactionDialogComponent implements OnInit {

  @Input() public response: TransactionInput;
  @Input() public onclose: Function;
  @Input() public onretry: Function;
  @Input() private localization: Localization;
  private height: number;
  public retry: boolean = false;
  constructor(private bsModalRef: BsModalRef,
              private sanitizer: DomSanitizer) {
    this.height = 0;
  }

  public card_front: SafeUrl;
  public card_back: SafeUrl;

  public extracted_face: SafeUrl;
  public face_aligned: SafeUrl;
  public score: number = 100;

  public card_front_fields: any;
  public card_back_fields: any;
  public card_front_barcodes: any;
  public card_back_barcodes: any;
  public card_type: any;

  public closeButtonlabel = "Close";
  public description: SafeHtml;

  public matcher_response: any;
  public ocr_output: any;
  public face_output: any;
  public finger_output: any;

  public txn_icon: string = "";
  public txn_icon_color: string = "";
  private error: boolean = true;
  private sim_score: number;
  ngOnInit() {
    setTimeout(() => {
      if (this.response.ocr_output && this.response.ocr_output.code === 600) {
        const m = this.localization.getString("FEEDBACK_LICENCE_INVALID");
        this.description = this.sanitizer.bypassSecurityTrustHtml(m)
        return;
      }

      console.log(this.response);
      this.ocr_output = this.response?.ocr_output;
      this.face_output = this.response?.face_output;
      this.finger_output = this.response?.finger_output;
      this.matcher_response = this.response?.matcher_output;
      if(this.matcher_response && this.matcher_response.score) {
        this.sim_score = this.matcher_response.score;
      }
      if (this?.face_output?.aligned) {
        this.face_aligned = "data:image/jpg;base64," + this.face_output.aligned;
      }
      if (this.ocr_output) {
        const ocr = this.ocr_output;
        if (this.response.card_face) {
          this.extracted_face = "data:image/jpg;base64," + this.response.card_face;
        }
        if(!this.extracted_face && this.response.face_output) {
          this.extracted_face = "data:image/jpg;base64," + this.face_output.aligned;
        }
        if (ocr.FRONT) {
          this.card_front = "data:image/jpg;base64," + ocr.FRONT.image;
          this.card_type = ocr.FRONT.type;
          if (ocr.FRONT.fields) {
            this.card_front_fields = ocr.FRONT.fields;
          }
          if (ocr.FRONT.barcodes) {
            this.card_front_barcodes = ocr.FRONT.barcodes;
          }
        }

        if (ocr.BACK) {
          this.card_back = "data:image/jpg;base64," + ocr.BACK.image;
          if (ocr.BACK.fields) {
            this.card_back_fields = ocr.BACK.fields;
          }
          if (ocr.BACK.barcodes) {
            this.card_back_barcodes = ocr.BACK.barcodes;
          }
        }
      }

      let message: SafeHtml = "Internal Server Error";
      if (!this.face_output && this.ocr_output) {
        message = "Captured Successfully..";
        this.error = false;
      } else if (this?.face_output === "SPOOF") {
        message = "Something is wierd, Try again!";
      } else if (this?.matcher_response?.possibleMatch) {
        message = "Your face has been verified.";
        this.error = false;
      } else if (!this?.matcher_response?.possibleMatch) {
        message = "Your face has not been verified.";
      }

      this.txn_icon = this.error ? "fa-times-circle" : "fa-check-circle";
      this.txn_icon_color = this.error ? "#ff0000" : "#01751e"
      this.description = message

    }, 40);
  }

  onCloseClicked() {
    this.bsModalRef.hide();
    if (this.onclose) {
      this.onclose();
    }
  }

  onRetryClicked() {
    this.bsModalRef.hide();
    if (this.onretry) {
      this.onretry();
    }
  }

  public showLarge(imageS: any) {
    const image = new Image();
    image.src = imageS;
    const w = window.open("");
    w.document.write(image.outerHTML);
  }
}


