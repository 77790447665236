import {Component, Input, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap";

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.css']
})
export class ErrorDialogComponent implements OnInit {

  constructor(private dialog_ref: BsModalRef) { }
  @Input() private onclose: Function;

  ngOnInit(): void {}


  onCloseClicked() {
    if (this.onclose) {
      this.onclose();
    }
    this.dialog_ref.hide();
  }
}
