import {Component, Input, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap";

@Component({
  selector: 'app-no-face-dialog',
  templateUrl: './no-face-dialog.component.html',
  styleUrls: ['./no-face-dialog.component.css']
})
export class NoFaceDialogComponent implements OnInit {
  @Input() private onclose: Function;
  @Input() private onretry: Function;
  constructor(private dialog_ref: BsModalRef) {}

  ngOnInit(): void {}

  onCloseClicked() {
    if (this.onclose) {
      this.onclose();
    }
    this.dialog_ref.hide();
  }

  onRetryClicked() {
    if(this.onretry) {
      this.onretry();
    }
    this.dialog_ref.hide();
  }

}
