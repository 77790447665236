import { Injectable } from "@angular/core";
import {Subject} from "rxjs";
import {MenuSelection} from "../../../modules/models/menu-selection";
import {AppUI, DisplayOrientation, Template, DocumentType} from '@identy/identy-ocr';

@Injectable({
  providedIn: "root"
})
export class ContextMenuHelperService {

  private run_face_subject: Subject<boolean> = new Subject<boolean>();
  private run_finger_subject: Subject<boolean> = new Subject<boolean>();
  public readonly cardSelection: Subject<DocumentType> = new Subject<DocumentType>();

  public selection: MenuSelection = new MenuSelection();

  constructor() {
    this.selection = new MenuSelection();
    this.run_face_subject.subscribe((run_face) => {
      this.selection.run_face = run_face;
    })

    this.run_finger_subject.subscribe((run_finger) => {
      this.selection.run_face = run_finger;
    })

    this.cardSelection.subscribe((card) => {
      // @ts-ignore
      console.log(card);
      this.selection.card_type = card;
    });
  }

}
