import {Component, Input, OnInit} from '@angular/core';
import {Utils} from "../../../../modules/helpers/Utils";

@Component({
  selector: 'app-extracted-face',
  templateUrl: './extracted-face.component.html',
  styleUrls: ['./extracted-face.component.css']
})
export class ExtractedFaceComponent implements OnInit {

  @Input() extracted: string;
  constructor() { }

  ngOnInit(): void {}

  showLarge(image_string) {
    Utils.showLarge(image_string);
  }

}
