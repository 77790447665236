import { Injectable } from "@angular/core";
import { ajax } from "jquery";
import { TransactionMode } from "@identy/identy-ocr";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import {
  Template as FaceTemplates,
  SdkOptionsType as FaceSDKOptionsType,
  AsThreshold,
  FaceSDK,
  Base64,
} from "@identy/identy-face";
import { ContextMenuHelperService } from "./components/title-bar/title-context-menu/context-menu-helper.service";
import { ProgressDialog } from "./components/sdk-run/progress_dialog_renderer";
import { environment } from "src/environments/environment";
import { SpoofDialogComponent } from "./components/dialogs/spoof-dialog/spoof-dialog.component";

@Injectable({ providedIn: "root" })

/**
 * Auth-service Component
 */
export class SdkService {
  isLocalhost =
    window.location.origin.includes("localhost") ||
    window.location.origin.includes("192.16");
  username = this.isLocalhost ? "localhost" : null;
  constructor(
    private contextMenuSelection: ContextMenuHelperService,
    private modalService: BsModalService
  ) {
    this.pre_init_face();
  }

  progress_dialog_ref: ProgressDialog;
  face_promise: any;
  face_Sdk: FaceSDK;
  no_face: BsModalRef;

  protected delay = (millis) =>
    new Promise<void>((resolve, reject) => {
      setTimeout((_) => resolve(), millis);
    });

  ngOnInit() {}

  reInit() {
    this.face_Sdk.abort();
  }

  pre_init_face() {
    if (!this.face_promise) {
      this.face_promise = new Promise<void>((resolve) => {
        let modelURL = environment.face_url + "/api/v1/models";
        FaceSDK.preInitialize({ URL: modelURL }).catch((err) => {
          console.error(err);
        });

        setTimeout(() => {
          resolve();
        }, 3000);
      });
    }
  }

  async capture_face(assisted?:boolean) {
    let face_response: any = await this.run_till_capture_face(assisted);
    return face_response;
  }

  async run_till_capture_face(assisted?:boolean) {
    if (!this.contextMenuSelection.selection.run_face) {
      return null;
    }
    await this.face_promise;
    return new Promise((resolve, reject) => {
      return this.run_capture_face(assisted)
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          console.log("this is the error", err.message);
          let captureError: string;
          if (err.message == "FEEDBACK_CAPTURE_TIMEOUT") {
            captureError = "Error: Capture Timeout";
          } else {
            captureError = "Capture Failed! Try again later.";
          }
          const ref = this.modalService.show(SpoofDialogComponent, {
            class: "modal-dialog-centered modal-sm txn-modal-dialog",
            initialState: {
              data: captureError,
              onclose: () => {
                ref.hide();
                resolve({
                  data: "SPOOF",
                });
              },
              onretry: () => {
                ref.hide();
                this.run_till_capture_face().then((cap_response: any) => {
                  resolve(cap_response);
                });
              },
            },
          });
        });
    });
  }

  async run_capture_face(assisted?:boolean) {
    await this.initialize_face(assisted);
    const face_blob = await this.face_Sdk.capture();
    return this.post_face_data(face_blob);
  }

  async initialize_face(assisted?:boolean) {
    if (this.face_Sdk) {
      return Promise.resolve();
    } else {
      console.log("initialize_face_else failed", this.face_Sdk);
    }
    console.log("Initializing Face");
    console.log("Initializing Face", assisted);
    const options: FaceSDKOptionsType = {
      enableAS: true,
      requiredTemplates: [FaceTemplates.PNG],
      base64EncodingFlag: Base64.NO_WRAP,
      showCaptureTraining: true,
      assisted:assisted,
      transaction: {
        type: TransactionMode.CAPTURE,
      },
      allowClose: true,
      asThreshold: AsThreshold.BALANCED_VERY_HIGH,
      silentInit: true,
      enableEyesStatusDetector: true,
      graphics: {
        canvas: {
          canvasBackground: "white",
          labelBackground: "green",
          ovalBorderBackground: "green",
          tickerActiveBackground:"green",
          tickerDefaultBackground:"yellow",
          ovalBorderErrorBackground:"red"
        }
      },
      enableICAOChecks: true,
      enableBackgroundRemoval: true,
    };

    this.face_Sdk = new FaceSDK(options);
    return this.face_Sdk.initialize();
  }

  post_face_data(face_blob) {
    return this.post_data(face_blob);
  }

  post_data(capresult: Blob, show_progress: boolean = true) {
    return new Promise((resolve, reject) => {
      if (show_progress) {
        this.progress_dialog_ref?.hide();
        this.progress_dialog_ref = new ProgressDialog("Processing", true);
      }

      const fd = new FormData();
      fd.append("file", capresult, `bdata`);
      ajax({
        url: `${
          environment.face_url
        }/api/v1/process?ts=${new Date().getTime()}`,
        contentType: false,
        processData: false,
        method: "POST",
        dataType: "JSON",
        data: fd,
        headers: {
          "X-DEBUG": this.username,
        },
      })
        .done((response: any) => {
          this.progress_dialog_ref?.hide();
          console.log("POST: RESPONSE");
          if (response.code !== 200) {
            return reject(response);
          }
          return resolve(response);
        })
        .fail((err) => {
          this.progress_dialog_ref?.hide();
          return reject(err);
        });
    });
  }
}
