import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-spoof-dialog',
  templateUrl: './spoof-dialog.component.html',
  styleUrls: ['./spoof-dialog.component.css']
})
export class SpoofDialogComponent implements OnInit {
  @Input() private onclose: Function;
  @Input() private onretry: Function;
  @Input() public data: Function;
  constructor() { }

  ngOnInit(): void {
    console.log('this is the data from parent ==>', this.data)
  }

  onCloseClicked() {
    if (this.onclose) {
      this.onclose();
    }
  }

  onRetryClicked() {
    if(this.onretry) {
      this.onretry();
    }
  }

}
