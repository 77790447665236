<div class="btn-group" dropdown [autoClose]="false">
  <button id="button-basic" dropdownToggle type="button"  class="btn btn-primary dropdown-toggle btn-menu" aria-controls="dropdown-basic">
    <i class="fa fa-ellipsis-v"></i>
  </button>
  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right"
      role="menu" aria-labelledby="button-basic">

    <li role="menuitem">
      <a class="dropdown-item" href="#">
        <div class="flex-container" style="width: 200px">
          <div class="flex-item label">Face</div>
          <div class="flex-item item">
            <input type="checkbox" [(ngModel)]="run_face" (click)="update()"/>
          </div>
        </div>
      </a>
    </li>

    <li class="dropdown-divider"></li>

    <li role="menuitem">
      <a class="dropdown-item">
        <div class="flex-container" style="width: 200px">
          <div class="flex-item label">Finger</div>
          <div class="flex-item item">
            <input type="checkbox" [(ngModel)]="run_finger" (click)="update()"/>
          </div>
        </div>
      </a>
    </li>
  <li role="menuitem">
      OCR Version: {{ocr_version}}, Finger Version: {{finger_version}}, Face Version: {{face_version}}
  </li>
  </ul>
</div>
