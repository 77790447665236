import {Component, Input, OnInit} from '@angular/core';
import {Utils} from "../../../../modules/helpers/Utils";
import {ContextMenuHelperService} from "../../../title-bar/title-context-menu/context-menu-helper.service";

@Component({
  selector: 'app-ocr-result',
  templateUrl: './ocr-result.component.html',
  styleUrls: ['./ocr-result.component.css']
})
export class OcrResultComponent implements OnInit {

  @Input() card_front_fields: any;
  @Input() card_front_image:  any;
  @Input() card_back_fields:  any;
  @Input() card_back_image:   any;
  public is_card:   boolean;
  constructor(private contextMenuSelection: ContextMenuHelperService) {
    this.is_card = !this.contextMenuSelection.selection.card_type.isA4;
  }

  ngOnInit(): void {
    console.log(this.card_front_image)
  }

  showLarge(image_string) {
    Utils.showLarge(image_string);
  }
}
