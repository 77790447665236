import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';

import { PagesRoutingModule } from './pages-routing.module';
import { BiometricCaptureComponent } from './biometric-capture/biometric-capture.component';
import { CaptureCompleteComponent } from './capture-complete/capture-complete.component';
import { BsModalService, ModalModule } from 'ngx-bootstrap';

@NgModule({
  declarations: [
    BiometricCaptureComponent,
    CaptureCompleteComponent,
  ],
  imports: [CommonModule, PagesRoutingModule, RouterModule, ModalModule, NgSelectModule, FormsModule],
  providers: [
    BsModalService,
  ],
})
export class PagesModule {}
