<div class="toolbar" role="banner">
  <div style="padding: 0px; height: 42px; width: 42px; border: 1px solid #000; margin-left: 5%; margin-right: 5%;">
    <img src="../../../assets/mtn-logo.png" style="width: 40px; height: 40px" />
  </div>
  <span style="color: #000">MTN FACE CAPTURE</span>
  <div class="spacer"></div>
</div>

<ng-template>
  <div class="content" role="main">
    <!-- <div class="row" style="margin-top: 35px; margin-bottom: 35px; justify-content: center;">
      <h3 style="text-align: center; font-size: 23px; font-weight: 700; font-family: 'Hind'; color: #000">Biometric
        Capture</h3>
    </div> -->

    <div class="row">

      <!-- <div class="col-12">
        <div
          style="padding: 25px 0px; margin-top: 30px; color: #ffcb00; background: #fff; text-align: center; border-radius: 7px; border: 1px solid #ffcb00;">

          <h4
            style="display: flex; color: #000; background: #fff; font-size: 18px; font-weight: 700; align-items: center; justify-content: center; cursor: pointer;"
            (click)="captureFace()">
            <i *ngIf="isFaceCaptureComplete" style="font-size: 24px; font-weight: bold; padding: 0 10px; color: #000; "
              class='bx bx-check-circle'>
            </i>
            Capture Face
          </h4>
        </div>
      </div> -->

      <!-- <div class="col-12" style="margin-top: 75px;">
        <div class="row" style="margin-bottom: 25px; margin-top: 15px; display: flex; justify-content: center; align-items: center;">
          <img [src]="transform()" style="width: 160px; height: 210px"/>
        </div>
      </div> -->

      <div class="col-12" style="display: flex; position: absolute; bottom: 40px; justify-content: center; align-items: center;">
        <div class="row" style=" align-content: center;">
          <button class="btn btn-block" style="background-color: #ffcb00; color: #fff !important; padding: 10px 35px;" (click)="refreshCapture()" >Refresh</button>
        </div>
      </div>

    </div>

  </div>
</ng-template>
<!-- <ng-template [ngIf]="isLoading">
  <div class="mask">
    <i class="pei pei-spinner"></i>
  </div>
</ng-template> -->