import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { Sdk2Service } from "src/app/sdk-2.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-biometric-capture",
  templateUrl: "./biometric-capture.component.html",
  styleUrls: ["./biometric-capture.component.scss"],
})
export class BiometricCaptureComponent implements OnInit {
  interval: any = null;
  faceInterval: any = null;
  isFingerCaptureComplete: Boolean = false;
  isFaceCaptureComplete: Boolean = false;
  is_child_capture: Boolean = false;

  // isLoading = true;

  webhook: string;
  auth_key: string;
  identifier: string;
  face_capture: string;
  clearedFace: string;

  // imgPath: "data:image/png;base64, ";
  base64Image: any;
  base64String: any;

  constructor(
    private router: Router,
    private sdkService: Sdk2Service,
    private readonly route: ActivatedRoute,
    private _sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.captureFace();
    // this.webhook = this.route.snapshot.params["webhook"];
    // this.auth_key = this.route.snapshot.params["auth_key"];
    // this.identifier = this.route.snapshot.params["identifier"];
    // console.log(
    //   "webhook ==>>",
    //   this.webhook,
    //   "auth_key ==>>",
    //   this.auth_key,
    //   "identifier ==>>",
    //   this.identifier
    // );

    // var requestOptions: any = {
    //   method: "GET",
    //   redirect: "follow",
    // };

    // fetch(
    //   `https://mtn-services.verxid.site/mtn-services/v1/authCheck?auth_key=${this.auth_key}`,
    //   requestOptions
    // )
    //   .then((response) => response.json())
    //   .then((result) => {
    //     // console.log(result.status);
    //     // console.log(typeof result.status);

    //     if (result.status && result.status == 1) {
    //       // console.log("Pass");
    //       this.isLoading = false;

    //       // call face capture method
    //       this.captureFace();
    //     } else {
    //       // console.log("fail");
    //       Swal.fire({
    //         title: "Error!",
    //         text: result.message,
    //         icon: "error",
    //         // showCancelButton: true,
    //         showConfirmButton: false,
    //         confirmButtonColor: "#3085d6",
    //         cancelButtonColor: "#d33",
    //         confirmButtonText: "Retry",
    //       });
    //     }
    //     // check
    //     // console.log("failed!");
    //     // console.log(result);
    //   })
    //   .catch((error) => {
    //     // console.log("error", error);
    //   });

    // set this to a valid base64 image
    // this.base64Image =
  }

  transform() {
    return this._sanitizer.bypassSecurityTrustResourceUrl(this.base64Image);
  }

  get canContinue() {
    return this.isFaceCaptureComplete && this.isFingerCaptureComplete;
  }

  copyMessage(val: string) {
    // console.log("this is the copy message ==", val);
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }

  refreshCapture() {
    // alert("refreshCapture");
    // this.sdkService.reInit()
    // this.captureFace();
    window.location.reload();
  }

  captureFace() {
    this.sdkService
      .capture_face()
      .then((res) => {
        console.log("this is the face capture response ==>>", res.data);

        // call logger service
        // var requestOptions: any = {
        //   method: "GET",
        //   redirect: "follow",
        // };

        // fetch(
        //   `https://mtn-services.verxid.site/mtn-services/v1/logFaceCapture?api_key=${this.auth_key}`,
        //   requestOptions
        // )
        //   .then((response) => response.text())
        //   // .then((result) => console.log(result))
        //   .catch((error) => console.log("error", error));

        // console.log("this is the face capture ==>>", res.data.templates.PNG);
        // const face_capture = res.data.templates.PNG;
        this.face_capture = res.data.templates.PNG;

        // check eyes closed
        console.log("eye stattus :: ", res.data.quality.eyes_status);
        console.log(
          "face_occlusions_check :: ",
          res.data.quality.face_occlusions_check
        );
        if (res.data.quality.eyes_status == "OPEN") {
          // analyze face
          Swal.fire({
            title: "Analyzing Face...",
            showConfirmButton: false,
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading(Swal.getDenyButton());
            },
          });

          // check for hand
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");

          var raw = JSON.stringify({
            image: this.face_capture,
          });

          var requestOptions: any = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };

          fetch(
            // "https://mtn-services.verxid.site/mtn-services/v1/faceDetect",
            "https://mtn-face-detect.verxid.site",
            requestOptions
          )
            .then((response) => response.json())
            .then((result) => {
              console.log("hand check result ==>>", result);
              if (result.status == 200) {
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                var raw = JSON.stringify({
                  image: this.face_capture,
                });

                var requestOptions: any = {
                  method: "POST",
                  headers: myHeaders,
                  body: raw,
                  redirect: "follow",
                };

                fetch(
                  "https://mtn-services.verxid.site/mtn-services/v1/faceDetect",
                  // "https://6f93-41-73-1-65.eu.ngrok.io/detectFace",
                  requestOptions
                )
                  .then((response) => response.json())
                  .then((result) => {
                    // console.log(
                    //   "face analysis complete...",
                    //   result,
                    //   "code",
                    //   result.status
                    // );
                    // console.log("code", result.status);

                    if (result.status == 200) {
                      Swal.close();
                      Swal.fire({
                        title: "Successful!",
                        text: "Face Analysis Passed!.",
                        icon: "success",
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes",
                      });
                      Swal.close();
                      this.backgroundRemoval();
                    } else {
                      Swal.fire({
                        title: "Detection Failed!",
                        text: result.message,
                        // text: "Keep a straight face and remove any object from face.",
                        icon: "error",
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Try Again",
                      }).then((isConfirm) => {
                        if (isConfirm.isConfirmed) {
                          this.captureFace();
                        }
                      });

                      window.parent.postMessage(
                        {
                          identifier: this.identifier,
                          authKey: this.auth_key,
                          status: 400,
                          message: "Capture failed!",
                        },
                        "*"
                      );
                    }
                  })
                  .catch((error) => {
                    // console.log("error ==>", error);
                  });
              } else {
                Swal.fire({
                  title: "Detection Failed!",
                  text: result.message,
                  // text: "Keep a straight face and remove any object from face.",
                  icon: "error",
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Try Again",
                }).then((isConfirm) => {
                  if (isConfirm.isConfirmed) {
                    this.captureFace();
                  }
                });

                window.parent.postMessage(
                  {
                    identifier: this.identifier,
                    authKey: this.auth_key,
                    status: 400,
                    message: "Capture failed!",
                  },
                  "*"
                );
              }
            })
            .catch((error) => {
              // console.log("error ==>", error);
            });
        } else if (res.data.quality.eyes_status == "CLOSED") {
          Swal.fire({
            title: "Capture Failed!",
            text: "Eyes Closed!",
            icon: "error",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Try Again",
          }).then((isConfirm) => {
            if (isConfirm.isConfirmed) {
              this.captureFace();
            }
          });

          window.parent.postMessage(
            {
              identifier: this.identifier,
              authKey: this.auth_key,
              status: 400,
              message: "Capture failed!",
            },
            "*"
          );
        }

        // if (
        //   res.data.quality.eyes_status == "OPEN" &&
        //   res.data.quality.face_occlusions_check == false
        // ) {
        //   // // analyze face
        //   // Swal.fire({
        //   //   title: "Analyzing Face...",
        //   //   showConfirmButton: false,
        //   //   allowEscapeKey: false,
        //   //   allowOutsideClick: false,
        //   //   didOpen: () => {
        //   //     Swal.showLoading(Swal.getDenyButton());
        //   //   },
        //   // });
        //   // // check for hand
        //   // var myHeaders = new Headers();
        //   // myHeaders.append("Content-Type", "application/json");
        //   // var raw = JSON.stringify({
        //   //   image: this.face_capture,
        //   // });
        //   // var requestOptions: any = {
        //   //   method: "POST",
        //   //   headers: myHeaders,
        //   //   body: raw,
        //   //   redirect: "follow",
        //   // };
        //   // fetch(
        //   //   // "https://mtn-services.verxid.site/mtn-services/v1/faceDetect",
        //   //   "https://mtn-face-detect.verxid.site",
        //   //   requestOptions
        //   // )
        //   //   .then((response) => response.json())
        //   //   .then((result) => {
        //   //     console.log("hand check result ==>>", result);
        //   //     if (result.status == 200) {
        //   //       var myHeaders = new Headers();
        //   //       myHeaders.append("Content-Type", "application/json");
        //   //       var raw = JSON.stringify({
        //   //         image: this.face_capture,
        //   //       });
        //   //       var requestOptions: any = {
        //   //         method: "POST",
        //   //         headers: myHeaders,
        //   //         body: raw,
        //   //         redirect: "follow",
        //   //       };
        //   //       fetch(
        //   //         "https://mtn-services.verxid.site/mtn-services/v1/faceDetect",
        //   //         // "https://6f93-41-73-1-65.eu.ngrok.io/detectFace",
        //   //         requestOptions
        //   //       )
        //   //         .then((response) => response.json())
        //   //         .then((result) => {
        //   //           // console.log(
        //   //           //   "face analysis complete...",
        //   //           //   result,
        //   //           //   "code",
        //   //           //   result.status
        //   //           // );
        //   //           // console.log("code", result.status);
        //   //           if (result.status == 200) {
        //   //             Swal.close();
        //   //             Swal.fire({
        //   //               title: "Successful!",
        //   //               text: "Face Analysis Passed!.",
        //   //               icon: "success",
        //   //               confirmButtonColor: "#3085d6",
        //   //               cancelButtonColor: "#d33",
        //   //               confirmButtonText: "Yes",
        //   //             });
        //   //             Swal.close();
        //   //             this.backgroundRemoval();
        //   //           } else {
        //   //             Swal.fire({
        //   //               title: "Detection Failed!",
        //   //               text: result.message,
        //   //               // text: "Keep a straight face and remove any object from face.",
        //   //               icon: "error",
        //   //               confirmButtonColor: "#3085d6",
        //   //               cancelButtonColor: "#d33",
        //   //               confirmButtonText: "Try Again",
        //   //             }).then((isConfirm) => {
        //   //               if (isConfirm.isConfirmed) {
        //   //                 this.captureFace();
        //   //               }
        //   //             });
        //   //             window.parent.postMessage(
        //   //               {
        //   //                 identifier: this.identifier,
        //   //                 authKey: this.auth_key,
        //   //                 status: 400,
        //   //                 message: "Capture failed!",
        //   //               },
        //   //               "*"
        //   //             );
        //   //           }
        //   //         })
        //   //         .catch((error) => {
        //   //           // console.log("error ==>", error);
        //   //         });
        //   //     } else {
        //   //       Swal.fire({
        //   //         title: "Detection Failed!",
        //   //         text: result.message,
        //   //         // text: "Keep a straight face and remove any object from face.",
        //   //         icon: "error",
        //   //         confirmButtonColor: "#3085d6",
        //   //         cancelButtonColor: "#d33",
        //   //         confirmButtonText: "Try Again",
        //   //       }).then((isConfirm) => {
        //   //         if (isConfirm.isConfirmed) {
        //   //           this.captureFace();
        //   //         }
        //   //       });
        //   //       window.parent.postMessage(
        //   //         {
        //   //           identifier: this.identifier,
        //   //           authKey: this.auth_key,
        //   //           status: 400,
        //   //           message: "Capture failed!",
        //   //         },
        //   //         "*"
        //   //       );
        //   //     }
        //   //   })
        //   //   .catch((error) => {
        //   //     // console.log("error ==>", error);
        //   //   });
        // } else if (
        //   res.data.quality.eyes_status == "CLOSED" &&
        //   (res.data.quality.face_occlusions_check == false ||
        //     res.data.quality.face_occlusions_check == true)
        // ) {
        //   // Swal.fire({
        //   //   title: "Capture Failed!",
        //   //   text: "Eyes Closed!",
        //   //   icon: "error",
        //   //   confirmButtonColor: "#3085d6",
        //   //   cancelButtonColor: "#d33",
        //   //   confirmButtonText: "Try Again",
        //   // }).then((isConfirm) => {
        //   //   if (isConfirm.isConfirmed) {
        //   //     this.captureFace();
        //   //   }
        //   // });
        //   // window.parent.postMessage(
        //   //   {
        //   //     identifier: this.identifier,
        //   //     authKey: this.auth_key,
        //   //     status: 400,
        //   //     message: "Capture failed!",
        //   //   },
        //   //   "*"
        //   // );
        // } else if (
        //   res.data.quality.eyes_status == "OPEN" &&
        //   res.data.quality.face_occlusions_check == true
        // ) {
        //   Swal.fire({
        //     title: "Liveliness Failed!",
        //     text: "Hand on face detected!",
        //     icon: "error",
        //     confirmButtonColor: "#3085d6",
        //     cancelButtonColor: "#d33",
        //     confirmButtonText: "Try Again",
        //   }).then((isConfirm) => {
        //     if (isConfirm.isConfirmed) {
        //       this.captureFace();
        //     }
        //   });

        //   window.parent.postMessage(
        //     {
        //       identifier: this.identifier,
        //       authKey: this.auth_key,
        //       status: 400,
        //       message: "Capture failed!",
        //     },
        //     "*"
        //   );
        // }
      })
      .catch((err) => {
        // console.log(err);
      });

    if (!this.faceInterval) {
      this.faceInterval = setInterval(
        function () {
          if (sessionStorage.getItem("face_capture")) {
            clearInterval(this.faceInterval);
            this.faceInterval = null;
            // alert('finger capture complete!');
            this.isFaceCaptureComplete = true;
          }
        }.bind(this),
        1000
      );
    }
  }

  backgroundRemoval() {
    Swal.fire({
      title: "Removing Background...",
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(Swal.getDenyButton());
      },
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      image: this.face_capture,
    });

    var requestOptions: any = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://mtn-services.verxid.site/mtn-services/v1/backgroundRemoval",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log("background removal complete...", result);

        this.clearedFace = result.image;
        // comparing face
        Swal.close();
        Swal.fire({
          title: "Comparing Face...",
          showConfirmButton: false,
          allowEscapeKey: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading(Swal.getDenyButton());
          },
        });

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          image: this.clearedFace,
        });

        var requestOptions: any = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch(
          "https://mtn-services.verxid.site/mtn-services/v1/face",
          requestOptions
        )
          // fetch("https://6f93-41-73-1-65.eu.ngrok.io/face", requestOptions)
          .then((response) => response.json())
          .then((result) => {
            // console.log(
            //   "face analysis complete...",
            //   result,
            //   "code",
            //   result.status
            // );
            // console.log("code", result.status);

            var base64regex =
              /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
            console.log(
              "is this a valid base64 ==??",
              base64regex.test(this.clearedFace)
            );

            if (result.status == 200 && base64regex.test(this.clearedFace)) {
              // send success capture
              Swal.close();
              Swal.fire({
                title: "Successful!",
                text: "Face Capture Successful.",
                icon: "success",
                // showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes",
              });

              // console.log("this is the JSON ==>>", {
              //   identifier: this.identifier,
              //   authKey: this.auth_key,
              //   image: this.clearedFace,
              //   status: result.status,
              //   message: "Capture successful!",
              // });

              // this.base64Image = `data:image/png;base64, ${this.clearedFace}`;
              // this.base64String = `${this.clearedFace}`;

              console.log("this is the face capture ==>>", this.clearedFace);

              window.parent.postMessage(
                {
                  identifier: this.identifier,
                  authKey: this.auth_key,
                  image: this.clearedFace,
                  status: result.status,
                  // message: result.message,
                  message: "Capture successful!",
                },
                "*"
              );
            } else {
              Swal.fire({
                title: "Face Capture Failed!",
                text: result.message,
                icon: "error",
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Try Again",
              }).then((isConfirm) => {
                if (isConfirm.isConfirmed) {
                  this.captureFace();
                }
              });

              window.parent.postMessage(
                {
                  identifier: this.identifier,
                  authKey: this.auth_key,
                  status: 400,
                  message: "Capture failed!",
                },
                "*"
              );
            }
          })
          .catch((error) => {
            // console.log("error ==>", error);
          });

        // old code
      })
      .catch((error) => {
        // Swal.close();
        // console.log("error ==>", error);
        window.parent.postMessage(
          {
            identifier: this.identifier,
            authKey: this.auth_key,
            status: 400,
            message: "Capture failed!",
          },
          "*"
        );
      });
  }

  cancel() {
    Swal.fire({
      title: "Cancel Enrollment",
      text: "Are you sure you want to cancel the enrollment?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((isConfirm) => {
      console.log("confirmed? ", isConfirm.isConfirmed);
      if (isConfirm.isConfirmed) {
        this.router.navigate(["/menu"]);
      }
    });
  }
}
