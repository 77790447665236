<div class="match-container">
  <div style="text-align: center">
    <h6>Score: {{fixed_score}} %</h6>
  </div>
  <div class="match-flex-grid">
    <div class="match-flex-item" style="text-align: center">
      <img src="{{extracted}}" alt="" class="match-img" (click)="showLarge(extracted)">
    </div>
    <div class="match-flex-item" style="text-align: center" (click)="showLarge(selfie)">
      <img src="{{selfie}}" alt="" class="match-img">
    </div>
  </div>
</div>
