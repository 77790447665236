<div class="ocr-result-container">
  <table class="table table-striped" style="font-size: 12px">
    <tr *ngIf="card_front_fields">
      <td>{{is_card ? "CARD" : "DOCUMENT"}} FRONT</td>
    </tr>
    <tr *ngFor="let item2 of card_front_fields | keyvalue; index as i">
      <td>{{item2.key}}</td>
      <td *ngIf="(item2.value | typeof) === 'string'">{{item2.value}}</td>
    </tr>

    <tr *ngIf="card_back_fields">
      <td>{{is_card ? "CARD" : "DOCUMENT"}} BACK</td>
    </tr>
    <tr *ngFor="let item2 of card_back_fields | keyvalue; index as i">
      <td>{{item2.key}}</td>
      <td *ngIf="(item2.value | typeof) === 'string'">{{item2.value}}</td>
    </tr>
  </table>
  <div class="ocr-result-image-grid">
      <div class="ocr-result-image-item">
        <img src="{{card_front_image}}" alt="" style="width: 90%;" (click)="showLarge(card_front_image)">
      </div>
      <div class="ocr-result-image-item">
        <img src="{{card_back_image}}" alt="" style="width: 90%;" (click)="showLarge(card_back_image)">
      </div>
  </div>
</div>
