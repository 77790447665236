import {Component, Input, OnInit} from '@angular/core';
import {Utils} from "../../../../modules/helpers/Utils";

@Component({
  selector: 'app-face-match',
  templateUrl: './face-match.component.html',
  styleUrls: ['./face-match.component.css']
})
export class FaceMatchComponent implements OnInit {

  @Input() extracted: string;
  @Input() selfie: string;
  @Input() score: number
  public fixed_score: number;
  constructor() { }

  ngOnInit(): void {
    this.fixed_score = this.sscore(this.score, "MEDIUM");
  }

  toFixed(num, fixed) {
    var re = new RegExp('^-?\\d+(?:\.\\d{0,' + (fixed || -1) + '})?');
    return num.toString().match(re)[0];
  }

  sscore(score: number, thresh:string) {
    const threshmap = {
      "HIGHEST" : 0.62, "VERY_HIGH": 0.64, "HIGH" : 0.68, "MEDIUM": 0.73
    }
    const tscore = threshmap[thresh];
    const min = 0.5 * tscore,
        max = 1.2071 * tscore;

    if (score <= min) return 1;
    if (score >= max) return 0;
    const dis = (score - min) / (tscore - min);
    return this.toFixed((100 * (1.0 - 0.5 * dis * dis)), 2);
  }

  showLarge(image_string) {
    Utils.showLarge(image_string);
  }

}
