<div class="toolbar" role="banner">
  <i (click)="goBack()" style="font-size: 19px; font-weight: bold; padding: 0 10px;" class='bx bx-arrow-back'>
  </i>
  <span>Verxid</span>
  <div class="spacer"></div>
</div>

<div class="content" role="main">
  <router-outlet></router-outlet>

  <footer>
    <h3>Log Out</h3>
  </footer>
</div>
