import { Component, OnInit } from "@angular/core";
import { ajax } from "jquery";
import {
  CardOcrSDK,
  SdkOptionsType,
  TransactionMode,
} from "@identy/identy-ocr";
import { ContextMenuHelperService } from "../title-bar/title-context-menu/context-menu-helper.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { TransactionDialogComponent } from "../dialogs/transaction-dialog/transaction-dialog.component";
import { CardSelectionHelperService } from "../card-selection/card-selection-helper.service";
import {
  Template as FaceTemplates,
  SdkOptionsType as FaceSDKOptionsType,
  AsThreshold,
  FaceSDK,
  Base64,
} from "@identy/identy-face";
import { environment } from "../../../environments/environment";
import { ProgressDialog } from "./progress_dialog_renderer";
import { UserInputComponent } from "../dialogs/user-input/user-input.component";
import { LocalizationMessage, url_to_base64 } from "@identy/identy-common";
import { SpoofDialogComponent } from "../dialogs/spoof-dialog/spoof-dialog.component";
import { NoFaceDialogComponent } from "../dialogs/no-face-dialog/no-face-dialog.component";
import { ErrorDialogComponent } from "../dialogs/error-dialog/error-dialog.component";
declare var WURFL: any;
import {
  Base64 as FingerBase64Options,
  FingerSDK,
  SdkOptionsType as FingerSdkOptionType,
  TransactionMode as FingerTransactionMode,
  Template as FingerTemplate,
  AsThreshold as FingerAsThreshold,
  FingerDetectionMode,
} from "@identy/identy-finger";
// import { Face } from "../../../../../../Projects/Identy-Code/Web-Code/Web-Clients/identy-web-sdks_3/identy-face/identySDK";
import { TransactionInput } from "../../modules/types";
import { delay } from "rxjs/operators";

@Component({
  selector: "app-sdk-run",
  templateUrl: "./sdk-run.component.html",
  styleUrls: ["./sdk-run.component.css"],
})
export class SdkRunComponent implements OnInit {
  isLocalhost =
    window.location.origin.includes("localhost") ||
    window.location.origin.includes("192.16");
  username = this.isLocalhost ? "localhost" : null;
  constructor(
    private contextMenuSelection: ContextMenuHelperService,
    private modalService: BsModalService,
    private cardFaceSelection: CardSelectionHelperService
  ) {
    // this.pre_init_finger();
    setTimeout(() => {
      this.pre_init_face();
    }, 3000);
  }

  progress_dialog_ref: ProgressDialog;
  ocr_retry: number = 0;
  face_promise: any;
  finger_promise: any;
  card_sdk: CardOcrSDK;
  face_Sdk: FaceSDK;
  finger_sdk: FingerSDK;
  allow: boolean = false;

  no_face: BsModalRef;
  protected delay = (millis) =>
    new Promise<void>((resolve, reject) => {
      setTimeout((_) => resolve(), millis);
    });
  ngOnInit() {
    setTimeout(() => {
      try {
        this.getUser().then((username) => {
          this.username = username;
          console.log(navigator.userAgent);
          console.log(navigator.userAgent);
          console.log(navigator.userAgent);
          console.log(navigator.userAgent);
          this.allow = true;
          const userId =
            (username || "default") + ":" + WURFL.complete_device_name;
          if (!this.isLocalhost) {
            console.log("Recording Web.......");
            //@ts-ignore
            window.LogRocket.identify({
              userID: userId,
            });
          }
        });
      } catch (e) {
        console.log(e);
      }
    }, 1000);
  }

  getUser() {
    return new Promise<string>((resolve) => {
      if (this.username) {
        return resolve(this.username);
      }
      console.log("User rendering");
      this.modalService.show(UserInputComponent, {
        initialState: {
          onclose: (username: string) => {
            console.log(`Username: ${username}`);
            resolve(username);
          },
        },
        ignoreBackdropClick: true,
      });
    });
  }

  async capture_face() {
    let face_response: any = await this.run_till_capture_face();
    console.log("face_response ==>>", face_response);
  }

  async capture_finger() {
    // alert('finger capture')
    let finger_response: any = await this.run_till_capture_finger();
    console.log("face_response ==>>", finger_response);
  }

  pre_init_face() {
    if (!this.face_promise) {
      this.face_promise = new Promise<void>((resolve) => {
        let modelURL = environment.face_url + "/api/v1/models";
        FaceSDK.preInitialize({URL: modelURL}).catch(err => {
          console.error(err);
        });
        // FaceSDK.preInitialize("QUVTAgAAANWtCpoBtrJ1rnZrXWpaoxJutH2b/LnnwZ7A1ikvt87v4I0D3KucB+WBsyCqJvU2A4phI18+YRUmnO1ByJspopPjS5yngclfAjn41Qcg7NWuvLAo0+3SSLPdIy2W4OKZ2zhX6bZoTb49K7MnwMB69AME4APTGDFarhdXvEumBVPJE+BNx5Np9CQdOnxBpGO28ZA=",{
        //   modelURL: `${environment.face_url}/api/v1/models`,
        // });

        setTimeout(() => {
          resolve();
        }, 3000);
      });
    }
  }

  // pre_init_finger() {
  //   if (!this.finger_promise) {
  //     this.finger_promise = FingerSDK.preInitialize({
  //       modelURL: `${environment.finger_url}/api/v1/models`,
  //     });
  //   }
  // }

  private async closeAllModals() {
    for (let i = 1; i <= this.modalService.getModalsCount(); i++) {
      this.modalService.hide(i);
    }
    await delay(100);
  }

  private get_face_from_ocr_response(ocrop: any) {
    let template_card_face;
    if (ocrop?.FRONT?.biometrics?.FACE?.image) {
      template_card_face = ocrop.FRONT.biometrics.FACE.image;
    }
    if (ocrop?.BACK?.biometrics?.FACE?.image) {
      template_card_face = ocrop.BACK.biometrics.FACE.image;
    }
    console.log("Getting Face from card output");
    console.log(template_card_face);
    return template_card_face;
  }

  async capture(): Promise<TransactionInput> {
    if (this.contextMenuSelection.selection.run_face) {
      this.pre_init_face();
    } else {
      // this.pre_init_finger();
    }
    let matcher_promise = Promise.resolve(null);

    let ocr_response: any = await this.run_till_capture_ocr();
    let ocr_output = ocr_response?.data;
    let face_response: any = await this.run_till_capture_face();
    let template_card_face = void 0;
    if (!this.contextMenuSelection.selection.card_type.isA4) {
      if (ocr_output?.FRONT?.biometrics?.FACE?.image) {
        template_card_face = ocr_output.FRONT.biometrics.FACE.image;
      }
      if (ocr_output?.BACK?.biometrics?.FACE?.image) {
        template_card_face = ocr_output.BACK.biometrics.FACE.image;
      }
      if (
        this.contextMenuSelection.selection.run_face &&
        template_card_face &&
        face_response.data &&
        face_response.data !== "SPOOF"
      ) {
        matcher_promise = this.post_to_matcher(
          template_card_face,
          face_response.data.templates.PNG
        );
      }
    }

    let combined_response: any = await Promise.all([
      matcher_promise,
      this.run_till_capture_finger(),
    ]);
    return {
      card_face: template_card_face,
      face_output: face_response?.data,
      ocr_output: ocr_output,
      matcher_output: combined_response[0],
      finger_output: combined_response[1]?.data,
    };
  }

  async initialize_ocr() {
    console.log("Initializing OCR");
    const options: SdkOptionsType = {
      allowClose: true,
      detectionModes: this.cardFaceSelection.cards,
      selectAsFile: false,
      cardtype: this.contextMenuSelection.selection.card_type,
      transaction: {
        type: TransactionMode.CAPTURE,
      },
      events: {},
      debug: true,
      urls: {
        URL: `${environment.face_url}/api/v1/model`,
      },
    };
    this.card_sdk = new CardOcrSDK(options);
    return this.card_sdk.initialize();
  }

  async initialize_face() {
    if (this.face_Sdk) {
      return Promise.resolve();
    }
    console.log("Initializing Face");
    const options: FaceSDKOptionsType = {
      enableAS: true,
      requiredTemplates: [FaceTemplates.PNG],
      base64EncodingFlag: Base64.NO_WRAP,
      showCaptureTraining: false,
      transaction: {
        type: TransactionMode.CAPTURE,
      },
      // backend: "webgl",
      allowClose: true,
      asThreshold: AsThreshold.HIGH,
      // urls: {
      //   modelURL: `${environment.face_url}/api/v1/model`,
      // },
      silentInit: true,
    };

    this.face_Sdk = new FaceSDK(options);
    return this.face_Sdk.initialize();
  }

  async initialize_finger() {
    if (this.finger_sdk) {
      return Promise.resolve();
    }
    console.log("Initializing Finger");
    const options: FingerSdkOptionType = {
      detectionModes: [FingerDetectionMode.L4F],
      enableAS: true,
      requiredTemplates: [],
      showCaptureTraining: true,
      base64EncodingFlag: FingerBase64Options.NO_WRAP,
      allowClose: true,
      skipSupportCheck: false,
      //backend: "wasm",
      transaction: {
        type: FingerTransactionMode.CAPTURE,
      },
      debug: true,
      asThreshold: FingerAsThreshold.HIGH,
      events: {},
    };

    this.finger_sdk = new FingerSDK(options);
    return this.finger_sdk.initialize();
  }

  async run_till_capture_ocr() {
    return new Promise(async (resolve, reject) => {
      let ocr_response: any;
      try {
        ocr_response = await this.run_capture_ocr();
      } catch (e) {
        return reject(e);
      }
      this.ocr_retry++;

      let ocr_output = ocr_response?.data;

      if (this.contextMenuSelection.selection.card_type.isA4) {
        return resolve(ocr_response);
      }
      let template_card_face = void 0;
      if (ocr_output?.FRONT?.biometrics?.FACE?.image) {
        template_card_face = ocr_output.FRONT.biometrics.FACE.image;
      }
      if (ocr_output?.BACK?.biometrics?.FACE?.image) {
        template_card_face = ocr_output.BACK.biometrics.FACE.image;
      }

      if (template_card_face) {
        return resolve(ocr_response);
      }

      const ref = this.modalService.show(NoFaceDialogComponent, {
        class: "modal-dialog-centered modal-sm txn-modal-dialog",
        initialState: {
          onclose: async () => {
            ref.hide();
            await this.closeAllModals();
            resolve(ocr_response);
          },
          onretry: () => {
            ref.hide();
            if (this.ocr_retry > 3) {
              return reject();
            }
            resolve(this.run_till_capture_ocr());
          },
        },
      });
    });
  }

  async run_capture_ocr() {
    await this.initialize_ocr();
    return this.card_sdk.capture();
  }

  async run_till_capture_face() {
    if (!this.contextMenuSelection.selection.run_face) {
      return null;
    }
    await this.face_promise;
    setTimeout(() => {
      if (
        this.contextMenuSelection.selection.run_finger &&
        !this.finger_promise
      ) {
        this.finger_promise = FingerSDK.preInitialize({
          URL: `${environment.face_url}/api/v1/models`,
        });
      }
    }, 10);
    return new Promise((resolve, reject) => {
      return this.run_capture_face()
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          console.log(err);
          const ref = this.modalService.show(SpoofDialogComponent, {
            class: "modal-dialog-centered modal-sm txn-modal-dialog",
            initialState: {
              onclose: () => {
                ref.hide();
                resolve({
                  data: "SPOOF",
                });
              },
              onretry: () => {
                ref.hide();
                this.run_till_capture_face().then((cap_response: any) => {
                  resolve(cap_response);
                });
              },
            },
          });
        });
    });
  }

  async run_capture_face() {
    await this.initialize_face();
    const face_blob = await this.face_Sdk.capture();
    return this.post_data(face_blob);
  }

  async run_till_capture_finger() {
    // if (!this.contextMenuSelection.selection.run_finger) {
    //   return null;
    // }
    await this.finger_promise;
    return new Promise((resolve, reject) => {
      return this.run_capture_finger()
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          const ref = this.modalService.show(SpoofDialogComponent, {
            class: "modal-dialog-centered modal-sm txn-modal-dialog",
            initialState: {
              onclose: () => {
                ref.hide();
                resolve(void 0);
              },
              onretry: () => {
                ref.hide();
                this.run_till_capture_finger().then((cap_response) => {
                  resolve(cap_response);
                });
              },
            },
          });
        });
    });
  }

  async run_capture_finger() {
    await this.initialize_finger();
    const finger_blob = await this.finger_sdk.capture();
    return this.post_data(finger_blob);
  }

  async trigger_capture() {
    try {
      const tip = await this.capture();
      const ref = this.modalService.show(TransactionDialogComponent, {
        class: "modal-dialog-centered modal-sm txn-modal-dialog",
        initialState: {
          response: tip,
          onclose: () => {
            ref.hide();
          },
          onretry: () => {},
        },
      });
    } catch (e) {
      if (e["getLocalizedString"]) {
        alert(e.getLocalizedString());
      }
    }
  }

  post_to_matcher(template1: string, template2: string) {
    if (this.progress_dialog_ref) {
      this.progress_dialog_ref.hide();
      this.progress_dialog_ref = null;
    }

    this.progress_dialog_ref = new ProgressDialog("Processing", true);
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        ajax({
          url: `${environment.face_url}/api/v1/matchWithPictureId`,
          method: "POST",
          dataType: "JSON",
          contentType: "application/json",
          data: JSON.stringify({
            type: "FACE",
            image: {
              template: template1,
              format: "PNG",
            },
            imageToVerifyAgainst: {
              template: template2,
              format: "PNG",
            },
            level: "MEDIUM",
          }),
        })
          .done((response: any) => {
            console.log("MATCH: RESPONSE");
            console.log(response);
            this.progress_dialog_ref?.hide();
            return resolve(response);
          })
          .fail((err) => {
            this.progress_dialog_ref?.hide();
            return reject(err);
          });
      }, 10);
    });
  }

  post_data(capresult: Blob, show_progress: boolean = true) {
    return new Promise((resolve, reject) => {
      if (show_progress) {
        this.progress_dialog_ref?.hide();
        this.progress_dialog_ref = new ProgressDialog("Processing", true);
      }

      const fd = new FormData();
      fd.append("file", capresult, `bdata`);
      ajax({
        url: `${environment.face_url}/api/v1/process?ts=${new Date().getTime()}`,
        contentType: false,
        processData: false,
        method: "POST",
        dataType: "JSON",
        data: fd,
        headers: {
          "X-DEBUG": this.username,
        },
      })
        .done((response: any) => {
          this.progress_dialog_ref?.hide();
          console.log("POST: RESPONSE");
          console.log(response);
          if (response.code !== 200) {
            return reject(response);
          }
          return resolve(response);
        })
        .fail((err) => {
          this.progress_dialog_ref?.hide();
          return reject(err);
        });
    });
  }
}
